import { ReactNode } from 'react';
export interface IAddressPayload {
  name: string;
  phoneNumber: string;
  address: string;
  coach?: string;
}

export type HeaderType = {
  route?: string;
  hiddenBackIcon?: boolean;
  title?: string;
  customTitle?: ReactNode;
  type?: 'primary' | 'secondary';
  rightIcon?: ReactNode;
  isShow?: boolean;
};
export interface IEditAddressPayload {
  name: string;
  phoneNumber: string;
  address: string;
  addressId: number;
}
export interface DataResponse<T> {
  data: T[];
  page: number;
  pageSize: number;
  totalPage: number;
  totalProducts: number;
}

export interface MenuItem {
  label: string;
  icon: ReactNode;
  activeIcon?: ReactNode;
}

export enum EProductTab {
  all = 'allProduct',
  suggestProduct = 'suggestProduct',
  formular = 'formular'
}

export enum EProductStatus {
  IN_ACTIVE = 'IN_ACTIVE',
  DISABLE = 'DISABLE'
}

export enum EDevice {
  ANDROID = 'android',
  IOS = 'ios'
}

export interface INotificationModal {
  isShow: boolean;
  buttonText?: string;
  title?: string;
  child?: React.JSX.Element;
  actions?: any;
}
