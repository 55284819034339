import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Icon } from 'zmp-ui';
import { headerState, pageAfterPayoutState } from '../state';
import { cx } from '../utils';

const typeColor = {
  primary: {
    headerColor: 'bg-primary',
    textColor: 'text-white',
    iconColor: 'text-white'
  },
  secondary: {
    headerColor: 'bg-white',
    textColor: 'text-black',
    iconColor: 'text-gray-400'
  }
};

const Header = () => {
  const { route, hiddenBackIcon, rightIcon, title, customTitle, type, isShow } =
    useRecoilValue(headerState);
  const isPageAfterPayout = useRecoilValue(pageAfterPayoutState);
  const { headerColor, textColor, iconColor } = typeColor[type! || 'primary'];
  const navigate = useNavigate();

  return (
    <div
      className={cx(
        'fixed top-0 z-20 w-screen h-header flex items-center',
        !isShow && 'hidden',
        headerColor,
        textColor
      )}>
      <div className=' flex items-center h-[44px] pl-3 pr-[105px] gap-3 w-full justify-between '>
        <div className='flex flex-row items-center w-full'>
          {!hiddenBackIcon && !isPageAfterPayout && (
            <span
              className='mr-1'
              onClick={() => (route ? navigate(route) : navigate(-1))}>
              <Icon icon='zi-arrow-left' className={iconColor} />
            </span>
          )}
          {customTitle || <div className='text-lg font-medium'>{title}</div>}
        </div>
        {rightIcon || ' '}
      </div>
    </div>
  );
};

export default Header;
