import React from 'react';
import { useRecoilValue } from 'recoil';
import { Spinner } from 'zmp-ui';
import { loadingState } from '../../state';

interface ILoadingProps {
  isLoading?: boolean;
}

export default function Loading({ isLoading }: ILoadingProps) {
  const isLoadingGlobal = useRecoilValue(loadingState);
  if (!isLoading && !isLoadingGlobal) {
    return;
  }
  return (
    <div className='fixed top-0 left-0 right-0 bottom-0  flex items-center justify-center z-[998] '>
      <Spinner />
    </div>
  );
}
