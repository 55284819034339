import { ERoute } from '@/types/router';
import importRetry from '@/utils/importRetry';
import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const HomePage = lazy(() => importRetry(() => import('@/pages/Home')));
const Cart = lazy(() => importRetry(() => import('@/pages/Cart')));
const EditOrder = lazy(() => importRetry(() => import('@/pages/EditOrder')));
const History = lazy(() => importRetry(() => import('@/pages/History')));
const CustomerInfo = lazy(() =>
  importRetry(() => import('@/pages/CustomerInfo'))
);
const PayoutInformation = lazy(() =>
  importRetry(() => import('@/pages/PayoutInformation'))
);
const SuccessOrder = lazy(() =>
  importRetry(() => import('@/pages/SuccessOrder'))
);
const OrderDetail = lazy(() =>
  importRetry(() => import('@/pages/OrderDetail'))
);
const Address = lazy(() => importRetry(() => import('@/pages/Address')));
const AddAddress = lazy(() => importRetry(() => import('@/pages/AddAddress')));
const LoadingApp = lazy(() => importRetry(() => import('@/pages/LoadingApp')));
const ChangePassword = lazy(() =>
  importRetry(() => import('@/pages/ChangePassword'))
);
const Formular = lazy(() => importRetry(() => import('@/pages/Formular')));

function AppRoutes() {
  return (
    <Routes>
      <Route path={'/'} element={<LoadingApp />} />
      <Route path={ERoute.home} element={<HomePage />} />
      <Route path={ERoute.cart} element={<Cart />} />
      <Route path={ERoute.editOrder} element={<EditOrder />} />
      <Route path={ERoute.orderHistory} element={<History />} />
      <Route path={ERoute.customerInfo} element={<CustomerInfo />} />
      <Route path={ERoute.changePassword} element={<ChangePassword />} />
      <Route path={ERoute.payoutInformation} element={<PayoutInformation />} />
      <Route path={ERoute.successOrder} element={<SuccessOrder />} />
      <Route
        path={`${ERoute.orderHistory}/:orderId`}
        element={<OrderDetail />}
      />
      <Route path={ERoute.address} element={<Address />} />
      <Route path={ERoute.addAddress} element={<AddAddress />} />
      <Route path={ERoute.formular} element={<Formular />} />

      <Route path='*' element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  );
}

export default AppRoutes;
