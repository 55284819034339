import { loginSuccessState } from '@/state';
import { SecureStorageEnum } from '@/types/auth';
import { Suspense, useEffect, useMemo } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { App, SnackbarProvider, Spinner } from 'zmp-ui';
import ConfirmModal from './components/Common/ConfirmModal';
import Loading from './components/Common/Loading';
import Header from './components/Header';
import { Navigation } from './components/Home/Navigation';
import NotificationModal from './components/Modals/NotificationModal';
import AppRoutes from './components/routes/AppRoutes';
import AuthRoute from './components/routes/AuthRoute';
import { usePushLayoutWhenKeyboardShown } from './hooks/usePushLayoutWhenKeyboardShown';
// import Maintain from './pages/Maintain';
import { useNetworkState } from '@uidotdev/usehooks';
import { toast } from 'react-toastify';

const MyApp = () => {
  const network = useNetworkState();
  console.log('network: ', network);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 3,
        refetchOnWindowFocus: true
      }
    }
  });

  usePushLayoutWhenKeyboardShown();

  const isLoginSuccess = useRecoilValue(loginSuccessState);

  const accessToken = localStorage.getItem(SecureStorageEnum.accessToken);
  const render = useMemo(() => {
    if (!accessToken) return <AuthRoute />;
    if (!!accessToken || isLoginSuccess) return <AppRoutes />;
  }, [accessToken, isLoginSuccess]);

  useEffect(() => {
    if (network?.rtt && network.rtt >= 1500) {
      toast('Kết nối mạng của bạn đang gặp vấn đề. Hãy thử kiểm tra lại.', {
        type: 'warning',
        theme: 'colored'
      });
    }
  }, [network?.rtt]);

  return (
    <QueryClientProvider client={queryClient}>
      <App>
        <Suspense
          fallback={
            <div className='w-screen h-screen flex justify-center items-center bg-transparent'>
              <Spinner />
            </div>
          }>
          <SnackbarProvider>
            <BrowserRouter>
              <Header />
              {render}
              <Navigation />
            </BrowserRouter>
          </SnackbarProvider>
        </Suspense>
        <NotificationModal />
        <ConfirmModal />
        <Loading />
      </App>

      {/*       <Maintain /> */}
    </QueryClientProvider>
  );
};
export default MyApp;
