import { loadingState } from '@/state';
import { ERoute } from '@/types/router';
import importRetry from '@/utils/importRetry';
import React, { lazy, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

const Login = lazy(() => importRetry(() => import('@/pages/Login')));
const LoadingAuth = lazy(() =>
  importRetry(() => import('@/pages/LoadingAuth'))
);

export default function AuthRoute() {
  const setloadingState = useSetRecoilState(loadingState);

  useEffect(() => {
    setloadingState(false);
  }, [setloadingState]);
  return (
    <Routes>
      <Route path={'/'} element={<LoadingAuth />} />
      <Route path={ERoute.login} element={<Login />} />
      <Route path='*' element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  );
}
