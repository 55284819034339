import { useVirtualKeyboardVisible } from '@/hooks/useVirtualKeyboardVisible';
import { confirmModalState, notificationModalState } from '@/state';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { BottomNavigation, Icon } from 'zmp-ui';
import { INotificationModal, MenuItem } from '../../types/common';
import { CartIcon } from '../Cart/CartIcon';

const tabs: Record<string, MenuItem> = {
  '/home': {
    label: 'Trang chủ',
    icon: <Icon icon='zi-home' />
  },
  '/order-history': {
    label: 'Lịch sử',
    icon: <Icon icon='zi-memory' />
  },
  '/finish-order': {
    label: 'Giỏ hàng',
    icon: <CartIcon />,
    activeIcon: <CartIcon active />
  },
  '/customer-info': {
    label: 'Cá nhân',
    icon: <Icon icon='zi-user' />
  }
};

export type TabKeys = keyof typeof tabs;

export const Navigation: FC = () => {
  const NO_BOTTOM_NAVIGATION_PAGES = ['/', '/login'];
  const [activeTab, setActiveTab] = useState<TabKeys>('/');
  const navigate = useNavigate();
  const location = useLocation();
  const isKeyboardOpen = useVirtualKeyboardVisible();
  const resetConfirmModal = useResetRecoilState(confirmModalState);
  const [notificationModal, setNotificationModal] = useRecoilState(
    notificationModalState
  );

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location.pathname]);

  const handleClickNavigation = useCallback(
    (path: string) => {
      resetConfirmModal();
      setNotificationModal({
        ...notificationModal,
        isShow: false
      } as INotificationModal);
      navigate(path);
    },
    [navigate, notificationModal, resetConfirmModal, setNotificationModal]
  );

  const noBottomNav = useMemo(() => {
    return NO_BOTTOM_NAVIGATION_PAGES.includes(location.pathname);
  }, [location]);

  if (noBottomNav || isKeyboardOpen) {
    return <></>;
  }

  return (
    <BottomNavigation
      id='footer'
      activeKey={activeTab}
      onChange={(key: TabKeys) => setActiveTab(key)}
      className='!z-max fixed bottom-0'>
      {Object.keys(tabs).map((path: TabKeys) => (
        <BottomNavigation.Item
          key={path}
          label={tabs[path].label}
          icon={tabs[path].icon}
          activeIcon={tabs[path].activeIcon}
          onClick={() => handleClickNavigation(path)}
        />
      ))}
    </BottomNavigation>
  );
};
