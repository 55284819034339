import { atom, selector } from 'recoil';
import { getUserInfo } from 'zmp-sdk';
import { ICart, ICartLocal } from './types/cart';
import { EProductTab, HeaderType, INotificationModal } from './types/common';
import { IAddress, ICustomer } from './types/customer';
import { IPayoutInfo } from './types/payout';
import { ERecoilKey } from './types/recoil';
import { getProductPrice } from './utils/common';
import { SecureStorageEnum } from './types/auth';

export const userState = selector({
  key: ERecoilKey.USER,
  get: () => getUserInfo({}).then(res => res.userInfo)
});

export const cartState = atom<ICart>({
  key: ERecoilKey.CART,
  default: {} as ICart
});

export const cartTotalPriceState = selector<number>({
  key: ERecoilKey.CART_TOTAL_PRICE,
  get: ({ get }) => {
    const cart = get(cartState);
    const result = cart?.products?.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
    return result || 0;
  }
});
export const cartTotalPriceLocalState = selector<number>({
  key: ERecoilKey.CART_TOTAL_PRICE_LOCAL,
  get: ({ get }) => {
    const cart = get(cartLocalState);
    const result = cart?.products?.reduce(
      (total, item) =>
        total +
        (getProductPrice(item.product.quantity, item.product.productPriceSet) ||
          0) *
          item.product.quantity,
      0
    );
    return result || 0;
  }
});
export const editOrderTotalPriceState = selector<number>({
  key: ERecoilKey.EDIT_ORDER_TOTAL_PRICE,
  get: ({ get }) => {
    const cart = get(editOrderState);
    const result = cart?.products?.reduce(
      (total, item) =>
        total +
        (getProductPrice(item.quantity, item.product.productPriceSet) || 0) *
          item.quantity,
      0
    );
    return result || 0;
  }
});

export const headerState = atom<HeaderType>({
  key: ERecoilKey.HEADER,
  default: {}
});

export const loadingState = atom<boolean>({
  key: ERecoilKey.LOADING,
  default: false
});

export const defaultAddressState = atom<IAddress>({
  key: ERecoilKey.DEFAULD_ADDRESS,
  default: {} as IAddress
});

export const payoutInfoState = atom<IPayoutInfo>({
  key: ERecoilKey.PAYOUT_INFO,
  default: {} as IPayoutInfo
});

export const showInputModalState = atom<boolean>({
  key: ERecoilKey.SHOW_INPUT_MODAL,
  default: false
});
export const confirmModalState = atom<any>({
  key: ERecoilKey.CONFIRM_MODAL,
  default: {}
});

export const notificationModalState = atom<INotificationModal>({
  key: ERecoilKey.NOTIFICATION_MODAL,
  default: {} as INotificationModal
});
export const activeTabState = atom<EProductTab>({
  key: ERecoilKey.ACTIVE_TAB,
  default: EProductTab.all
});
export const tabFistTimeState = atom<boolean>({
  key: ERecoilKey.TAB_FIRST_TIME,
  default: false
});

export const productFocusState = atom<number>({
  key: ERecoilKey.PRODUCT_FOCUS,
  default: undefined
});
export const wordEnteredDisplayState = atom<string>({
  key: ERecoilKey.WORD_ENTERED_DISPLAY,
  default: ''
});

export const pageAfterPayoutState = atom<boolean>({
  key: ERecoilKey.PAGE_AFTER_PAYOUT,
  default: false
});

export const noteTextOrderState = atom<string>({
  key: ERecoilKey.TEXT_NOTE_ORDER,
  default: ''
});

export const homeSearchTextState = atom<string>({
  key: ERecoilKey.HOME_SEARCH_TEXT,
  default: ''
});
export const homeSearchTextDisplayState = atom<string>({
  key: ERecoilKey.HOME_SEARCH_TEXT_DISPLAY,
  default: ''
});

export const numberOpenKeyBoardState = atom<number>({
  key: ERecoilKey.NUMBER_OPEN_KEYBOARD,
  default: 0
});

export const orderCodeState = atom<string>({
  key: ERecoilKey.ORDER_CODE,
  default: undefined
});

export const hasDisplayedUnpaidOrderState = atom<boolean>({
  key: ERecoilKey.HAS_DISPLAY_UNPAID_ORDER_MODAL,
  default: false
});

export const scrollProductPositionState = atom<number>({
  key: ERecoilKey.SCROLL_PRODUCT_POSITION,
  default: 0
});

export const hasDisplayedPosterState = atom<boolean>({
  key: ERecoilKey.HAS_DISPLAY_POSTER_MODAL,
  default: false
});

export const editOrderState = atom<ICart>({
  key: ERecoilKey.EDIT_ORDER_STATE,
  default: {} as ICart
});

export const totalToPaidOrderEditState = atom<number>({
  key: ERecoilKey.TOTAL_TO_PAID_ORDER_EDIT,
  default: 0
});
export const requestPermisionState = atom<boolean>({
  key: ERecoilKey.REQUEST_PERMISSION_STATE,
  default: false
});

export const customerInfoState = atom<ICustomer>({
  key: ERecoilKey.CUSTOMER_INFO_STATE,
  default: {} as any
});

export const cartLocalState = atom<ICartLocal>({
  key: ERecoilKey.CART_LOCAl,
  default: {} as ICartLocal
});

const accessToken = localStorage.getItem(SecureStorageEnum.accessToken);

export const loginSuccessState = atom<boolean>({
  key: ERecoilKey.LOGIN_SUCCESS_STATE,
  default: !!accessToken
});
