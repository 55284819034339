export interface IOrder {
  id: number;
  code: string;
  isPaid?: boolean;
  isPrinted?: boolean;
  createdAt: string;
  customer: {
    name: string;
  };
  status: EOrderStatus;
  totalPrices: number;
  paid: number;
  totalProducts: number;
  isHistory: boolean;
}

export enum EOrderStatus {
  draft = 'DRAFT',
  pending = 'PENDING',
  succeed = 'SUCCEED',
  paid = 'PAID',
  printed = 'PRINTED'
}

export interface IOrderImagePayout {
  orderId: number;
  verificationPhotoUrls: string[];
}

export interface IOrderImagePayoutResponse {
  error: number;
  message: string;
  data: {
    urls: string[];
  };
}
export interface IImageFile {
  id: number;
  url: string;
}

export interface IImageVerificationResponse {
  orderFile: IImageFile[];
}
