import { useEffect } from 'react';

export const usePushLayoutWhenKeyboardShown = () => {
  useEffect(() => {
    const listener = () => {
      if (document.activeElement && document.activeElement.tagName == 'INPUT') {
        window.setTimeout(function () {
          (document.activeElement as any).scrollIntoViewIfNeeded(false);
        }, 0);
      }
    };

    if (typeof visualViewport != 'undefined') {
      window.visualViewport?.addEventListener('resize', listener);
    }
    return () => {
      if (typeof visualViewport != 'undefined') {
        window.visualViewport?.removeEventListener('resize', listener);
      }
    };
  }, []);
};
