import { notificationModalState } from '@/state';
import { INotificationModal } from '@/types/common';
import React, { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { Modal } from 'zmp-ui';

export default function NotificationModal() {
  const [notificationModal, setNotificationModal] = useRecoilState(
    notificationModalState
  );

  const handleClose = useCallback(() => {
    setNotificationModal({
      ...notificationModal,
      isShow: false
    } as INotificationModal);
  }, [notificationModal, setNotificationModal]);

  const modalButton = useMemo(() => {
    return [
      {
        text: notificationModal.buttonText || 'Đã hiểu',
        close: true,
        onClick: handleClose,
        style: {
          borderRadius: '8px',
          background: '#679210',
          color: ' var(--zmp-page-bg-color)',
          height: '40px',
          fontSize: '14px',
          padding: '0 24px',
          fontWeight: 700,
          marginTop: '16px'
        }
      }
    ];
  }, [handleClose, notificationModal]);

  return (
    <Modal
      className='unpaidOrderModal notificationModal'
      onClose={handleClose}
      visible={notificationModal.isShow}
      title={notificationModal.title}
      actions={notificationModal.actions || modalButton}>
      {notificationModal.child}
    </Modal>
  );
}
