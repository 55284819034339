import { confirmModalState, showInputModalState } from '@/state';
import React, { useEffect, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Modal } from 'zmp-ui';
import last from 'lodash/last';
import { cx } from '@/utils';

export default function ConfirmModal() {
  const modalData = useRecoilValue(confirmModalState);
  const {
    isShow,
    handleConfirm,
    description,
    title,
    handleCancel,
    confirmText,
    hiddenRightButon,
    notPressOutsiteClose,
    customCss
  } = modalData;
  const setModalData = useSetRecoilState(confirmModalState);
  const setShowInputModal = useSetRecoilState(showInputModalState);

  const modalButton = useMemo(() => {
    return [
      {
        text: 'Bỏ qua',
        close: true,
        onClick: () => {
          handleCancel();
          setModalData({ ...modalData, isShow: false });
        },
        style: {
          borderRadius: '8px',
          background: '#fff',
          color: '#679210',
          height: '36px',
          fontSize: '14px',
          padding: '0 12px',
          fontWeight: 700,
          width: '70px',
          border: '1px solid'
        }
      },
      {
        text: confirmText || 'Xóa',
        onClick: () => {
          handleConfirm && handleConfirm();
          setModalData({ ...modalData, isShow: false });
        },
        style: {
          borderRadius: '8px',
          background: '#679210',
          color: 'white',
          height: '36px',
          fontSize: '14px',
          padding: '0 16px',
          fontWeight: 700,
          width: '70px'
        }
      }
    ];
  }, [confirmText, handleCancel, setModalData, modalData, handleConfirm]);

  useEffect(() => {
    setShowInputModal(isShow);
  }, [isShow, setShowInputModal]);

  return (
    <div className={cx('section-container', customCss)}>
      <Modal
        maskClosable={!notPressOutsiteClose}
        visible={isShow}
        title={title || 'Xác nhận'}
        onClose={() => {
          setModalData({ ...modalData, isShow: false });
        }}
        description={description}
        actions={!hiddenRightButon ? (modalButton as any) : [last(modalButton)]}
        modalClassName='px-4'
      />
    </div>
  );
}
