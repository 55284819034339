import { Palette } from '@/constants/color';
import { EDevice, EProductTab } from '@/types/common';
import { EOrderStatus } from '@/types/order';
import { IPriceSet } from '@/types/product';
import { SECURE_SROGARE_ENUM } from '@/types/secureStorage';
import dayjs from 'dayjs';
import { isNil, last } from 'lodash';
import { REGEX_CHECK_PHONE_NUMBER } from '../constants/common';
import { roundToNearestThousand } from './convert-price';
import secureStorage from './secureStorage';

export const checkInvalidPhoneNumber = (phoneNumber: string) =>
  REGEX_CHECK_PHONE_NUMBER.test(phoneNumber);

export const convertDateToIso = (date: string) => {
  if (!date) {
    return '';
  }
  const isoTime = new Date(date);
  return isoTime.toISOString();
};

export const getProductPrice = (
  productCount: number,
  priceSet: IPriceSet[]
) => {
  let priceSetAProduct;
  if (productCount === 0) {
    priceSetAProduct = priceSet?.find(
      (item: IPriceSet) => item.minAmount === 1
    );
  } else {
    priceSetAProduct = priceSet?.find(
      (item: IPriceSet) =>
        productCount <= item.maxAmount && productCount >= item.minAmount
    );
  }

  if (isNil(priceSetAProduct)) {
    return last(priceSet)?.price;
  }
  return priceSetAProduct?.price;
};

export const productTabs = [
  {
    name: EProductTab.all,
    label: 'Tất cả sản phẩm'
  },
  {
    name: EProductTab.suggestProduct,
    label: 'Sản phẩm đã mua'
  },
  {
    name: EProductTab.formular,
    label: 'Kho công thức'
  }
];

export const getAmountToPaid = (
  totalPrice: number,
  debt: number,
  shippingFee: number,
  discount: number
) => {
  const total = totalPrice + debt + shippingFee - discount;
  if (total <= 0) {
    return 0;
  } else {
    return total;
  }
};
export const getAccessToken = async () => {
  const accessToken = secureStorage.getItem(SECURE_SROGARE_ENUM.ACCESS_TOKEN);
  return accessToken;
};

export const checkAndroidOrIOS = () => {
  const userAgent = navigator.userAgent;
  if (/android/i.test(userAgent)) {
    return EDevice.ANDROID;
  }
  if (/iPad|iPhone|iPod/i.test(userAgent)) {
    return EDevice.IOS;
  }
};

export const regexOrderCode = /^DH(\d+)$/;

export const errorUploadImageModal = {
  isShow: true,
  title: 'Thông báo',
  description: 'Ảnh tải lên quá 10MB. Vui lòng tải lên lại.',
  confirmText: 'Đã hiểu',
  hiddenRightButon: true,
  notPressOutsiteClose: true
};

export const getOrderDiscount = (discount: number, total: number) =>
  !discount ? 0 : roundToNearestThousand(Math.ceil((total / 100) * discount));

export const handleBlur = () => {
  const activeElement = document?.activeElement as any;
  activeElement.blur();
};

export const getCssStatusText = (isPaid: boolean) => {
  if (!isPaid) {
    return {
      color: Palette.error,
      text: 'Chưa chuyển khoản hoặc chuyển khoản sai nội dung'
    };
  } else {
    return {
      color: '#679210',
      text: 'Đã thanh toán'
    };
  }
};

export const getOrderStatusText = (isPrinted: boolean, type: string) => {
  if (isPrinted) {
    if (type === EOrderStatus.succeed) {
      return {
        color: '#679210',
        text: 'Đã hoàn thành'
      };
    } else {
      return {
        color: '#679210',
        text: 'Đang chuẩn bị hàng'
      };
    }
  } else {
    return {
      color: '#679210',
      text: 'Đơn hàng đang chờ xác nhận'
    };
  }
};

export const discountPercent = (totalPrice: number) => {
  const currentHour = Number(dayjs().hour());
  if (currentHour > 17 || currentHour < 8) {
    switch (true) {
      case totalPrice >= 10000000:
        return 2;
      case totalPrice >= 5000000:
        return 1.5;

      default:
        return 1;
    }
  } else {
    return 0;
  }
};
