export enum ERecoilKey {
  USER = 'user',
  CART = 'cart',
  CART_TOTAL_PRICE = 'cartTotalPrice',
  CART_TOTAL_PRICE_LOCAL = 'cartTotalPriceLocal',
  EDIT_ORDER_TOTAL_PRICE = 'editOrderTotalPriceState',
  HEADER = 'header',
  LOADING = 'loading',
  DEFAULD_ADDRESS = 'defaultAddress',
  PAYOUT_INFO = 'payoutInfo',
  SHOW_INPUT_MODAL = 'showInputModalState',
  CONFIRM_MODAL = 'confirmModalStates',
  NOTIFICATION_MODAL = 'notificationModalState',
  ACTIVE_TAB = 'activeTabState',
  TAB_FIRST_TIME = 'tabFistTimeState',
  PRODUCT_FOCUS = 'productFocus',
  WORD_ENTERED_DISPLAY = 'wordEnteredDisplay',
  PAGE_AFTER_PAYOUT = 'pageAfterPayoutState',
  TEXT_NOTE_ORDER = 'noteTextOrderState',
  HOME_SEARCH_TEXT = 'homeSearchTextState',
  HOME_SEARCH_TEXT_DISPLAY = 'homeSearchTextDisplay',
  NUMBER_OPEN_KEYBOARD = 'numberOpenKeyBoardState',
  ORDER_CODE = 'orderCode',
  HAS_DISPLAY_UNPAID_ORDER_MODAL = 'hasDisplayedUnpaidOrder',
  SCROLL_PRODUCT_POSITION = 'scrollProductPosition',
  HAS_DISPLAY_POSTER_MODAL = 'hasDisplayedPoster',
  EDIT_ORDER_STATE = 'editOrderState',
  TOTAL_TO_PAID_ORDER_EDIT = 'totalToPaidOrderEdit',
  REQUEST_PERMISSION_STATE = 'requestPermissionState',
  CUSTOMER_INFO_STATE = 'customerInfoState',
  CART_LOCAl = 'cartLocal',
  LOGIN_SUCCESS_STATE = 'loginSuccessState'
}
