export enum ERoute {
  login = 'login',
  payoutInformation = '/payout-information',
  suggestCart = '/suggest-cart',
  address = '/address',
  addAddress = '/add-address',
  home = '/home',
  loading = '/loading',
  cart = '/finish-order',
  landingPage = '/landingPage',
  customerInfo = '/customer-info',
  orderHistory = '/order-history',
  successOrder = '/success-order',
  editOrder = '/edit-order/:orderCode',
  changePassword = '/change-password',
  formular = '/formular'
}
